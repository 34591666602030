<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>Change Password</strong>
            </div>
            <b-row>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.currentPassword"
                  label="Current Password"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="password"
                    placeholder="Enter current password"
                    autocomplete="off"
                    v-model="form.currentPassword"
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.newPassword"
                  label="New Password"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="password"
                    placeholder="Enter new password"
                    autocomplete="off"
                    v-model="form.newPassword"
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.confirmNewPassword"
                  label="Repeat New Password"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="password"
                    placeholder="Repeat new password"
                    autocomplete="off"
                    v-model="form.confirmNewPassword"
                  ></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button
                type="submit"
                size="sm"
                variant="success"
                class="float-right"
              >
                Save Changes
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  data: () => ({
    form: {
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
    },
  }),
  validations: {
    form: {
      currentPassword: { required },
      newPassword: { required },
      confirmNewPassword: {
        required,
        sameAsNewPassword: sameAs("newPassword"),
      },
    },
  },
  methods: {
    handleSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      let _confirmText =
          "You are about to update your password. Are you sure ?",
        _okText = "Yes, Update",
        _action = "apis/post",
        _url = `/user/change-password`;

      self.$dialog
        .confirm(_confirmText, {
          okText: _okText,
          cancelText: "Cancel",
          loader: true,
        })
        .then((dialog) => {
          self.$store
            .dispatch(_action, {
              url: _url,
              params: self.form,
            })
            .then((response) => {
              dialog.close();
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });
              }
            });
        });
    },
  },
};
</script>